import {
    commonInvoiceListInvoicesSortOptions,
    invoicesSortByOptions,
} from '../../constants/invoicesSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    DynamicObject,
} from '../../utils/commonInterfaces';
import {
    Attachment,
    ConversationLine,
    ConversationState,
    CustomField,
    PageData,
} from '../common/types';
import { Customer } from '../customers/types';
import { Company } from '../companies/types';
import { Task } from '../tasks/types';
import { PaymentPlan } from '../paymentPlans/types';

export enum InvoicesActionTypes {
    GET_INVOICES_REQUEST = '@@invoices/GET_INVOICES_REQUEST',
    GET_INVOICES_FOR_ORGANISATION_REQUEST = '@@invoices/GET_INVOICES_FOR_ORGANISATION_REQUEST',
    GET_INVOICES_SUCCESS = '@@invoices/GET_INVOICES_SUCCESS',
    GET_INVOICES_ERROR = '@@invoices/GET_INVOICES_ERROR',
    UPDATE_INVOICES_FILTERS = '@@invoices/UPDATE_INVOICES_FILTERS',
    UPDATE_INVOICES_SORT_BY_AND_STATE = '@@invoices/UPDATE_INVOICES_SORT_BY_AND_STATE',
    UPDATE_INVOICES_TABLE_FILTER_STATE = '@@invoices/UPDATE_INVOICES_TABLE_FILTER_STATE',
    CLEAR_INVOICES_STATE_ALL_TABLE_FILTERS = '@@invoices/CLEAR_INVOICES_STATE_ALL_TABLE_FILTERS',
    CLEAR_INVOICES_STATE_DATA = '@@invoices/CLEAR_INVOICES_STATE_DATA',
    CLEAR_INVOICES_LIST_DATA = '@@invoices/CLEAR_INVOICES_LIST_DATA',
    GET_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@invoices/GET_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',
    GET_INVOICES_FOR_PAYMENT_PLAN_REQUEST = '@@invoices/GET_INVOICES_FOR_PAYMENT_PLAN_REQUEST',

    SET_INVOICE_SELECTED_ID_REQUEST = '@@invoices/SET_INVOICE_SELECTED_ID_REQUEST',
    SET_INVOICE_SELECTED_ID_SUCCESS = '@@invoices/SET_INVOICE_SELECTED_ID_SUCCESS',
    GET_INVOICE_DATA_REQUEST = '@@invoices/GET_INVOICE_DATA_REQUEST',
    GET_ORGANISATION_INVOICE_DATA_REQUEST = '@@invoices/GET_ORGANISATION_INVOICE_DATA_REQUEST',
    GET_INVOICE_DATA_SUCCESS = '@@invoices/GET_INVOICE_DATA_SUCCESS',
    GET_INVOICE_DATA_ERROR = '@@invoices/GET_INVOICE_DATA_ERROR',
    CLEAR_INVOICE_DATA_SUCCESS = '@@invoices/CLEAR_INVOICE_DATA_SUCCESS',

    CHECK_INVOICES_IF_SINGLE_CUSTOMER_REQUEST = '@@invoices/CHECK_INVOICES_IF_SINGLE_CUSTOMER_REQUEST',
    RAISE_QUERY_INVOICE_BULK_REQUEST = '@@invoices/RAISE_QUERY_INVOICE_BULK_REQUEST',

    // Conversation
    GET_INVOICE_CONVERSATION_REQUEST = '@@invoices/GET_INVOICE_CONVERSATION_REQUEST',
    GET_ORGANISATION_INVOICE_CONVERSATION_REQUEST = '@@invoices/GET_ORGANISATION_INVOICE_CONVERSATION_REQUEST',
    GET_INVOICE_CONVERSATION_SUCCESS = '@@invoices/GET_INVOICE_CONVERSATION_SUCCESS',
    GET_INVOICE_CONVERSATION_ERROR = '@@invoices/GET_INVOICE_CONVERSATION_ERROR',
    UPDATE_INVOICE_CONVERSATION_FILTERS = '@@invoices/UPDATE_INVOICE_CONVERSATION_FILTERS',
    UPDATE_INVOICE_CONVERSATION_TABLE_FILTER = '@@invoices/UPDATE_INVOICE_CONVERSATION_TABLE_FILTER',
    UPDATE_INVOICE_CONVERSATION_SORT_BY = '@@invoices/UPDATE_INVOICE_CONVERSATION_SORT_BY',

    // Changes
    GET_INVOICE_INVOICE_CHANGES_REQUEST = '@@invoices/GET_INVOICE_INVOICE_CHANGES_REQUEST',
    GET_ORGANISATION_INVOICE_CHANGES_REQUEST = '@@invoices/GET_ORGANISATION_INVOICE_CHANGES_REQUEST',
    GET_INVOICE_INVOICE_CHANGES_SUCCESS = '@@invoices/GET_INVOICE_INVOICE_CHANGES_SUCCESS',
    GET_INVOICE_INVOICE_CHANGES_ERROR = '@@invoices/GET_INVOICE_INVOICE_CHANGES_ERROR',
    UPDATE_INVOICE_INVOICE_CHANGES_FILTERS = '@@invoices/UPDATE_INVOICE_INVOICE_CHANGES_FILTERS',
    UPDATE_INVOICE_INVOICE_CHANGES_TABLE_FILTER = '@@invoices/UPDATE_INVOICE_INVOICE_CHANGES_TABLE_FILTER',
    UPDATE_INVOICE_INVOICE_CHANGES_SORT_BY = '@@invoices/UPDATE_INVOICE_INVOICE_CHANGES_SORT_BY',

    INVOICE_ADD_COMMENT_REQUEST = '@@invoices/INVOICE_ADD_COMMENT_REQUEST',
    ORGANISATION_INVOICE_ADD_COMMENT_REQUEST = '@@invoices/ORGANISATION_INVOICE_ADD_COMMENT_REQUEST',

    CHECK_INVOICES_PAUSE_RESUME_STATE = '@@invoices/CHECK_INVOICES_PAUSE_RESUME_STATE',
    INVOICE_PAUSE_RESUME_REQUEST = '@@invoices/INVOICE_PAUSE_RESUME_REQUEST',

    INVOICE_SET_TABLE_COMPACT_VIEW = '@@invoices/INVOICE_SET_TABLE_COMPACT_VIEW',
    INVOICES_FETCHED_FOR_ORGANISATION_RECEIVED = '@@invoices/INVOICES_FETCHED_FOR_ORGANISATION_RECEIVED',

    INVOICE_CONVERSATION_LINES_FETCHED_FOR_ORGANISATION_RECEIVED = '@@invoices/CONVERSATION_LINES_FETCHED_FOR_ORGANISATION_RECEIVED',
    INVOICE_CHANGE_LINES_FETCHED_FOR_ORGANISATION_RECEIVED = '@@invoices/CHANGE_LINES_FETCHED_FOR_ORGANISATION_RECEIVED',
    
    // Batch Payment Plan
    GET_PAYMENT_PLAN_CONFIG_TEMPLATES_REQUEST = '@@invoices/GET_PAYMENT_PLAN_CONFIG_TEMPLATES_REQUEST',
    GET_PAYMENT_PLAN_CONFIG_TEMPLATES_SUCCESS = '@@invoices/GET_PAYMENT_PLAN_CONFIG_TEMPLATES_SUCCESS',
    GET_PAYMENT_PLAN_CONFIG_TEMPLATES_FAILED = '@@invoices/GET_PAYMENT_PLAN_CONFIG_TEMPLATES_FAILED',
    GET_INVOICES_HAVE_NO_PAYMENT_PLAN_REQUEST = '@@invoices/GET_INVOICES_HAVE_NO_PAYMENT_PLAN_REQUEST',
    GET_INVOICES_HAVE_NO_PAYMENT_PLAN_SUCCESS = '@@invoices/GET_INVOICES_HAVE_NO_PAYMENT_PLAN_SUCCESS',
    GET_INVOICES_HAVE_NO_PAYMENT_PLAN_FAILED = '@@invoices/GET_INVOICES_HAVE_NO_PAYMENT_PLAN_FAILED',
    UPDATE_BATCH_PAYMENT_PLAN_REVIEWING_INVOICES_DATA = '@@invoices/UPDATE_BATCH_PAYMENT_PLAN_REVIEWING_INVOICES_DATA',
    APPLY_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS = '@@invoices/APPLY_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS',
    RESET_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS = '@@invoices/RESET_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS',
    GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_REQUEST = '@@invoices/GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_REQUEST',
    GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_SUCCESS = '@@invoices/GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_SUCCESS',
    GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_FAILED = '@@invoices/GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_FAILED',
    APPLY_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS = '@@invoices/APPLY_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS',
    RESET_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS = '@@invoices/RESET_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS',
    APPLY_REVIEWING_BATCH_PAYMENT_PLAN = '@@invoices/APPLY_REVIEWING_BATCH_PAYMENT_PLAN',
    SELECT_PAYMENT_PLAN_TEMPLATE = '@@invoices/SELECT_PAYMENT_PLAN_TEMPLATE',
    SUBMIT_BATCH_PAYMENT_PLAN_REQUEST = '@@invoices/SUBMIT_BATCH_PAYMENT_PLAN_REQUEST',
    UPDATE_BATCH_PAYMENT_PLAN_FORM_DATA_REQUEST = '@@invoices/UPDATE_BATCH_PAYMENT_PLAN_FORM_DATA_REQUEST',
    UPDATE_BATCH_PAYMENT_PLAN_FORM_DATA = '@@invoices/UPDATE_BATCH_PAYMENT_PLAN_FORM_DATA',
    RESET_BATCH_PAYMENT_PLAN_STATE = '@@invoices/RESET_BATCH_PAYMENT_PLAN_STATE',
    UPDATE_BATCH_PAYMENT_PLAN_CUSTOMER_FILTERS = '@@invoices/UPDATE_BATCH_PAYMENT_PLAN_CUSTOMER_FILTERS',
    UPDATE_BATCH_PAYMENT_PLAN_INVOICE_FILTERS = '@@invoices/UPDATE_BATCH_PAYMENT_PLAN_INVOICE_FILTERS',
    GET_CUSTOMER_INVOICES_FOR_COMPANY_REQUEST = '@@invoices/GET_CUSTOMER_INVOICES_FOR_COMPANY_REQUEST',
    GET_CUSTOMER_INVOICES_FOR_COMPANY_SUCCESS = '@@invoices/GET_CUSTOMER_INVOICES_FOR_COMPANY_SUCCESS',
    GET_CUSTOMER_INVOICES_FOR_COMPANY_FAILED = '@@invoices/GET_CUSTOMER_INVOICES_FOR_COMPANY_FAILED',
}

export interface InvoicesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Invoice[];
    readonly pageData: PageData;
    readonly filters: GetInvoicesRequestPayload['filters'];
    readonly sortBy: GetInvoicesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly invoiceState: string;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        invoiceChanges: InvoiceChangesState;
        conversation: ConversationState;
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly compactView: boolean;
    readonly batchPaymentPlan: {
        readonly invoices: {
            readonly panelFilters: InvoiceHaveNoPaymentPlanFilters;
            readonly loading: boolean;
            readonly data: Invoice[];
            pageData: PageData;
        };
        readonly customers: {
            readonly panelFilters: InvoiceHaveNoPaymentPlanFilters;
            readonly loading: boolean;
            readonly data: Customer[];
            pageData: PageData;
        };
        readonly paymentPlanConfigs: {
            readonly loading: boolean;
            readonly data: BatchPaymentPlanConfigModel[];
            readonly selectedTemplateId: string | undefined;
        };
        readonly customerManagementFilter: any;
        readonly invoiceManagementFilter: any;
        readonly formData: BatchPaymentPlanFormModel;
        readonly reviewingBatchPaymentPlan: {};
        readonly reviewingInvoice: {
            readonly loading: boolean;
            readonly data: Invoice[];
        };
    };
}

export interface BatchPaymentPlanFormModel {
    readonly customerIds: string[] | [];
    readonly excludedItems: InvoiceHaveNoPaymentPlanSelectedModel[] | [];
    readonly invoiceItems: InvoiceHaveNoPaymentPlanSelectedModel[] | [];
    readonly paymentPlanConfig: BatchPaymentPlanConfigModel | undefined;
    readonly skipCustomerNotification: boolean;
    readonly isIndividualInvoice: boolean;
    readonly requestFromPage: string | undefined;
}

export interface BatchPaymentPlanConfigModel {
    ConfigId: string | undefined;
    InstalmentType: string |undefined;
    Instalments: number |undefined;
    ScheduleDates?: string[] | [];
    PayPercent?: number[] | [];
    SplitInstalmentDateRange?: string[] | [];
    Name?: string | undefined;
    CutoffDate?: string | undefined;
    StartDelay?: number | undefined;
    FrequencyGap?: number | undefined;
    SpecificPaymentDate?: number | undefined;
    StartTiming?: string | undefined;
}

export interface InvoiceHaveNoPaymentPlanSelectedModel {
    InvoiceId?: string;
    CustomerId: string;
    TotalAmount: number;
}

export interface DeliveryDetails {
    Type: string;
    State: string;
    Comment: string;
    Reason: string;
}
export interface Invoice {
    readonly Id: string;
    readonly CompanyId: string;
    readonly InvoiceCode: string;
    readonly Number: string;
    readonly CreatedDate: string;
    readonly LocalCreatedDate: string;
    readonly DueDate: string;
    readonly LocalDueDate: string;
    readonly OriginalAmount: number;
    readonly AmountOwing: number;
    readonly State: string;
    readonly SettledDate: string;
    readonly LocalSettledDate: string;
    readonly Type: string;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
    readonly Customer: Customer;
    readonly ConversationLine: ConversationLine;
    readonly CustomFields: CustomField[];
    readonly Attachments: Attachment[];
    readonly LastCommunication: LastCommunication;
    readonly CurrentTask: Task;
    readonly IsBlocked: boolean;
    readonly Company: Company;
    readonly GlCode: string;
    readonly PromiseToPayDate: string;
    readonly LocalPromiseToPayDate: string;
    readonly PaymentPlan: PaymentPlan;
}

export interface InvoiceHaveNoPaymentPlanFilters {
    InvoiceCode: string | undefined;
    CustomerCode: string | undefined;
    Customer: string | undefined;
}

export interface DeliveryDetails {
    Type: string;
    State: string;
    Comment: string;
    Reason: string;
}

export interface GetInvoicesRequestPayload {
    filters: {
        InvoiceNumber: string;
        Customer: string;
        CustomerCode: string;
        CustomerId?: string;
        CreatedDateMin: string;
        CreatedDateMax: string;
        DueDateMin: string;
        DueDateMax: string;
        Amount?: DynamicObject;
        AmountType: string;
        AmountValue: string;
        AmountOperator: string;
        InvoiceIds?: string[];
        CompanyIds?: string[];
        CompanyId?: string;
    };
    excludeInvoices?: boolean;
    invoiceState: string;
    sortBy: typeof invoicesSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    usingCustomerWorkflow?: boolean;
    isPaymentPlanEnabled?: boolean;
    region?: string;
    conversionCurrency?: number;
    organisationCurrencies?: InvoiceOrganisationCurrency[]
    callback?: (response: any) => void;
}

export interface GetCommonInvoiceListInvoicesRequestPayload {
    filters: {};
    sortBy: typeof commonInvoiceListInvoicesSortOptions[number]['value'];
    sortAscending: boolean;
    invoiceState: string;
    pageSize: number;
    currentPage: number;
}

export interface InvoiceAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    invoiceIds: string[] | number[];
    excludeInvoices: boolean;
}

export interface GetInvoiceConversationRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    conversationTableFilter: string;
}

export interface InvoicePauseResumeRequestPayload {
    filter: {};
    invoiceIds: string[] | number[];
    excludeInvoices: boolean;
    isBlocked?: boolean;
    callback?: Function;
}
export interface LastCommunication {
    WorkflowName: string;
    StateName: string;
    SentDateTime: string;
    DeliveryDetails: DeliveryDetails[];
}

export interface GetInvoiceChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    invoiceChangesTableFilter: string;
}

export interface InvoiceChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Invoice[];
    readonly pageData: PageData;
    readonly filters: GetInvoiceChangesRequestPayload['filters'];
    readonly invoiceChangesTableFilter: string;
    readonly sortBy: GetInvoiceChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface InvoiceOrganisationCurrency {
    Value: string
    Rate: number
}

export interface InvoiceChanges {}

export interface InvoicesFetchedData {
    OnInvoicesFetchedForOrganisation: {
        Invoices: Invoice[];
    };
};

export interface InvoicesFetchedForOrganisationPayload {
    data: InvoicesFetchedData;
    pageSize: number;
    currentPage: number;
}

export const InvoiceState = {
    OPEN: 'Open',
    ALL: 'All'
}