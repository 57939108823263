import React, { useState, Suspense, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceNoPaymentPlanListFromInvoicePage from './InvoiceNoPaymentPlanListFromInvoicePage';
import ReviewCreatedNewPaymentPlanPanel from './ReviewCreatedNewPaymentPlanPanel';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { ApplicationState } from '../../store';
import { cloneDeep, get, isEmpty, isUndefined, minBy, sum, sumBy } from 'lodash';
import { naiveRound } from '../../utils/contentFunctions';
import moment from 'moment-timezone';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import { computeTableScroll, getTranslatedText, getValidDayInMonth } from '../../utils/commonFunctions';
import BulkPaymentPlanSelectTemplateFormWrapper from './BulkPaymentPlanSelectTemplatePanel';
import { resetBatchPaymentPlanStateAction, submitBatchPaymentPlanAction } from '../../store/invoices/actions';
import { CUSTOMERS_PAGE, INVOICES_PAGE } from '../../config/tableAndPageConstants';
import { PaymentPlanRequestPageTypes } from '../../store/common/types';
import InvoiceNoPaymentPlanListFromCustomerPage from './InvoiceNoPaymentPlanListFromCustomerPage';
interface InvoiceNoPaymentPlanComponentProps {
    readonly visible: boolean;
    readonly closePanel: (refreshList?: boolean) => void;
    readonly customerLabel?: string | '';
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly requestFromPage?: string;
    readonly getSelectedInvoicesValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
    };
}

const InvoiceNoPaymentPlanPanel: React.FC<InvoiceNoPaymentPlanComponentProps> = ({
    visible,
    customerLabel,
    closePanel,
    formatCurrency,
    formatToParts,
    requestFromPage,
    getSelectedInvoicesValues,
    ... rest
}) => {
    const dispatch = useDispatch();
    const [ formStep, setFormStep ] = useState<number>(1);

    const handleClosePanel = (isClosePanel?: boolean) => {
        dispatch(resetBatchPaymentPlanStateAction());
        closePanel(isClosePanel);
    };

    const handleNextStepFormClick = () => {
        setFormStep((prevFormStep) => prevFormStep + 1);
    };

    const handleBackStepFormClick = (isClosePanel?: boolean) => {
        if (isClosePanel) {
            handleClosePanel(true);
            return;
        }

        setFormStep((prevForStep) => {
            if (prevForStep === 1) {
                return prevForStep;
            }
            return prevForStep - 1;
        });
    };

    const batchPaymentPlanState = useSelector((state: ApplicationState) => state.invoices.batchPaymentPlan);
    const reviewingInvoice = get(batchPaymentPlanState, 'reviewingInvoice', {});
    const invoices = get(reviewingInvoice, 'data', []);
    const reviewingBatchPaymentPlan = get(batchPaymentPlanState, 'reviewingBatchPaymentPlan', {});
    const selectedPaymentPlanConfig = get(batchPaymentPlanState, 'formData.paymentPlanConfig', {});
    const isIndividualInvoice = get(reviewingBatchPaymentPlan, 'IsIndividualInvoice');

    const reviewPaymentPlan = useMemo(() => {

        let paymentPlan = {};
        if (isEmpty(reviewingBatchPaymentPlan)) return paymentPlan;

        let firstInvoice = cloneDeep(invoices[0]) || {};

        if (!isIndividualInvoice) {
            firstInvoice.AmountOwing = sumBy(invoices, (invoice: any) => invoice.AmountOwing);
            firstInvoice.DueDate = minBy(invoices.map((i: any) => i.DueDate), (dueDate: any) => dueDate);
            firstInvoice.CreatedDate = minBy(invoices.map((i: any) => i.CreatedDate), (createdDate: any) => createdDate);
        }

        if (isEmpty(firstInvoice)) return paymentPlan;

        const paymentPlanSchedules: any[] = [];
        const invoiceAmount = get(firstInvoice, 'AmountOwing', 0);
        let instalmentItems = get(reviewingBatchPaymentPlan, 'InstalmentItems', []);
        if (instalmentItems.length === 0) {
            return paymentPlan;
        }

        if (get(reviewingBatchPaymentPlan, 'InstalmentType', '') === 'Monthly') {
            const startTiming = get(reviewingBatchPaymentPlan, 'StartTiming', '');
            let stringRefDate: string = '';
            switch (startTiming) {
                case 'CreateDate': 
                    stringRefDate = get(firstInvoice, 'CreatedDate', '');
                    break;
                case 'OverDueDate':
                    stringRefDate = get(firstInvoice, 'DueDate', '');
                    firstInvoice = invoices.find((invoice: any) => !isUndefined(invoice.DueDate) && !isEmpty(invoice.DueDate));
                    break;
                case 'RequestDate':
                    stringRefDate = moment().toISOString();
                default:
                    break;
            }

            let refDate: moment.Moment = moment(stringRefDate);
            if (isUndefined(refDate)) return paymentPlan;

            if (selectedPaymentPlanConfig.StartDelay) {
                refDate = refDate.add(selectedPaymentPlanConfig.StartDelay, 'months');
            }
            instalmentItems = instalmentItems.map((instalment: any, index: number) => {
                const dueDate = getValidDayInMonth(
                    index === 0 ? refDate : refDate.add(selectedPaymentPlanConfig.FrequencyGap, 'months'), 
                    selectedPaymentPlanConfig.SpecificPaymentDate
                );

                return {
                    ...instalment,
                    InstalmentDueDate: dueDate.format(dateFormatDDMMMYYYYSpace),
                }
            });
        }

        for(let i = 0; i < instalmentItems.length; i++) {
            const amount = naiveRound(invoiceAmount * (instalmentItems[i].AllocatePercentage / 100), 2);
            paymentPlanSchedules.push({
                Id: uuidv4(),
                Number: i + 1,
                TotalAmount: amount,
                AmountRemaining: amount,
                DueDateTime: moment(instalmentItems[i].InstalmentDueDate, dateFormatDDMMMYYYYSpace).toISOString(),
                LocalDueDateTime: moment(instalmentItems[i].InstalmentDueDate, dateFormatDDMMMYYYYSpace).toISOString(),
            }); 
        }

        paymentPlan = {
            ...paymentPlan,
            TotalAmount: paymentPlanSchedules.reduce((total, schedule) => total + schedule.TotalAmount, 0),
            PaymentPlanSchedules: paymentPlanSchedules
        };

        return paymentPlan;
    }, [reviewingBatchPaymentPlan, invoices]);

    const formData = get(batchPaymentPlanState, 'formData', {});
    const customerManagementFilter = get(batchPaymentPlanState, 'customerManagementFilter', null);
    const invoiceManagementFilter = get(batchPaymentPlanState, 'invoiceManagementFilter', null);
    
    if (!visible) return null;
    return (
        <>
            { formStep === 1 
                && requestFromPage === PaymentPlanRequestPageTypes.INVOICES_PAGE
                && (
                    <InvoiceNoPaymentPlanListFromInvoicePage
                        handleNextStepFormClick={handleNextStepFormClick}
                        closePanel={handleClosePanel}
                        formatCurrency={formatCurrency}
                        formatToParts={formatToParts}
                        getSelectedItemsValues={getSelectedInvoicesValues}
                        scroll={computeTableScroll(
                            window.innerHeight - 185,
                            INVOICES_PAGE.pageSize,
                            INVOICES_PAGE.rowHeight
                        )}
                        {... rest}
                    />
                )
            }

            { formStep === 1 
                && requestFromPage === PaymentPlanRequestPageTypes.CUSTOMERS_PAGE
                && (
                    <InvoiceNoPaymentPlanListFromCustomerPage
                        handleNextStepFormClick={handleNextStepFormClick}
                        closePanel={handleClosePanel}
                        formatCurrency={formatCurrency}
                        formatToParts={formatToParts}
                        getSelectedItemsValues={getSelectedInvoicesValues}
                        scroll={computeTableScroll(
                            window.innerHeight - 185,
                            CUSTOMERS_PAGE.pageSize,
                            CUSTOMERS_PAGE.rowHeight
                        )}
                        {... rest}
                    />
                )
            }

            { formStep === 2
                && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <BulkPaymentPlanSelectTemplateFormWrapper
                            handleNextStepFormClick={handleNextStepFormClick}
                            handleBackStepFormClick={handleBackStepFormClick}
                            customerLabel={customerLabel}
                        />
                    </Suspense>
                )
            }
            { formStep === 3
                && !isUndefined(reviewPaymentPlan)
                && !isEmpty(reviewPaymentPlan)
                && (
                    <ReviewCreatedNewPaymentPlanPanel
                        visible={true}
                        paymentPlan={reviewPaymentPlan}
                        isRequireDeposit={false}
                        closePanel={handleBackStepFormClick}
                        formatCurrency={formatCurrency}
                        dispatchAction={(callback?: any) => {
                            dispatch(submitBatchPaymentPlanAction({
                                formData,
                                customerManagementFilter,
                                invoiceManagementFilter, 
                                callback}));
                        }}
                        extraProps={{
                            Notes: getTranslatedText('The payment plan schedule preview of the first selected invoice'),
                            IsBatchPaymentPlan: true,
                            SkipCustomerNotification: get(reviewingBatchPaymentPlan, 'SkipCustomerNotification'),
                            IsIndividualInvoice: get(reviewingBatchPaymentPlan, 'IsIndividualInvoice'),
                            CustomerLabel: customerLabel,
                            ReviewPaymentPlanLoading: get(reviewingInvoice, 'loading', false),
                        }}
                    />
                )
            }
        </>
    );
};

export default withNumberFormatHandler(
    withDateFormatHandler(InvoiceNoPaymentPlanPanel)
);
