import React, { useState, useEffect } from "react";
import { Table, Row, Col, Spin, Input, Button, Icon } from "antd";
import { concat, filter, first, get, isEmpty, isEqual, isUndefined, map, uniqWith } from "lodash";
import { ApplicationState } from "../../store";
import { CompanyUserRole } from "../../store/companies/types";
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOMERS_PAGE, INVOICES_PAGE } from "../../config/tableAndPageConstants";
import { getTranslatedText } from "../../utils/commonFunctions";
import { 
    applyCustomerHaveNoPaymentPlanFiltersAction,
    getCustomersHaveNoPaymentPlanRequestAction,
    resetCustomerHaveNoPlaymentPlanFiltersAction,
    updateBatchPaymentPlanFormDataRequestAction} from "../../store/invoices/actions";
import { 
    Invoice, 
    InvoiceHaveNoPaymentPlanSelectedModel,
    InvoiceState 
} from "../../store/invoices/types";
import { pixelAllowanceScrollToFetchMore } from "../../config/config";
import { Customer, CustomersState } from "../../store/customers/types";
import { PaymentPlanRequestPageTypes } from "../../store/common/types";

const tablePageSize = CUSTOMERS_PAGE.pageSize;
interface InvoiceNoPaymentPlanListFromCustomerPageProps {
    readonly scroll?: any;
    handleNextStepFormClick: () => void;
    closePanel: (refreshList?: boolean) => void;
    formatCurrency?: (amount: number) => string;
    formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly getSelectedItemsValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
    };
}
let lastScrollTop = 0;

const InvoiceNoPaymentPlanListFromCustomerPage: React.FC<InvoiceNoPaymentPlanListFromCustomerPageProps> = ({ 
    scroll,
    handleNextStepFormClick,
    closePanel,
    formatCurrency,
    formatToParts,
    getSelectedItemsValues
}) => {
    const dispatch = useDispatch();
    const customersState: CustomersState = useSelector(
        (state: ApplicationState) => state.customers
    );  
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const batchPaymentPlanState = useSelector(
        (state: ApplicationState) => state.invoices.batchPaymentPlan
    );
    const batchPaymentPlanFormData = get(batchPaymentPlanState, 'formData', {});
    const batchPaymentPlanCustomerStates = get(batchPaymentPlanState, 'customers');
    const customerData = get(batchPaymentPlanCustomerStates, 'data');

    let customerDataToSelectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[] = [];
    if (!isUndefined(customerData) && !isEmpty(customerData)) {
        customerDataToSelectedRowKeys = customerData.map(
            (customer: Customer) => Object.assign({}, {
                CustomerId: get(customer, 'Id'),
                TotalAmount: get(customer, 'AgedTrialBalance.TotalOwing')
            }), 
        );
    }

    const panelFilters = get(batchPaymentPlanCustomerStates, 'panelFilters');
    const hasFilters = Object.values(panelFilters).some(
        (value) => !isUndefined(value) && !isEmpty(value)
    );
    
    const tableCurrentPage = get(batchPaymentPlanCustomerStates, 'pageData.currentPage', 0);
    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[];
        unselectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const { allExcept, keysToUse } = getSelectedItemsValues();
        let filters = {...panelFilters};

        filters.customerManagementFilter = {
            filters: customersState.filters,
            sortBy: customersState.sortBy,
            sortAscending: customersState.sortAscending,
            atbType: customersState.atbType,
            customer: panelFilters.Customer,
            customerCode: panelFilters.CustomerCode,
            customerIds: keysToUse,
            invoiceCode: panelFilters.InvoiceCode,
            invoiceIds: panelFilters.InvoiceIds,
            excludeCustomers: allExcept,
            hasPaymentPlan: false, 
            amountOperator: '>',
            amountType: 'Total owing',
            amountValue: 0
        }

        return {
            ...filters,
            pageSize,
            currentPage: currentPage
        };
    };

    /**
     * Function that handles fetching of customers (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchInvoicesHaveNoPaymentPlan = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isUndefined(selectedUserCompany) || isEmpty(selectedUserCompany)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);
        dispatch(getCustomersHaveNoPaymentPlanRequestAction(payload));
    };

    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleInvoiceData = () => {
        if (isEmpty(selectedUserCompany)
            || isUndefined(selectedUserCompany)
            || batchPaymentPlanCustomerStates.loading
        )
            return;
        if (!batchPaymentPlanCustomerStates.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        fetchInvoicesHaveNoPaymentPlan(nextPage);
    };

    const heightUsedScroll = scroll ? get(scroll, 'y') : '100%';
    const currentHeight = customerData.length > 0
        ? heightUsedScroll
        : 150;
    const usedHeight = currentHeight < INVOICES_PAGE.rowHeight 
        ? INVOICES_PAGE.rowHeight 
        : currentHeight;

    const handleTableScroll = (event: Event) => {
        const target = event.target as HTMLTableSectionElement;
        const { clientHeight, scrollHeight, scrollTop } = target;
        const currentScroll = clientHeight + scrollTop;
        const roundedCurrentScroll = Math.ceil(currentScroll) + pixelAllowanceScrollToFetchMore;
        const scrollDirectionDown = !(lastScrollTop > roundedCurrentScroll);
        lastScrollTop = roundedCurrentScroll;

        if (
            roundedCurrentScroll >= scrollHeight
            && batchPaymentPlanCustomerStates.pageData.hasNextPage
            && !batchPaymentPlanCustomerStates.loading
            && scrollDirectionDown
        ) {
            handleInvoiceData();
        }
    };

    const handleClosePanel = () => {
        dispatch(resetCustomerHaveNoPlaymentPlanFiltersAction());
        closePanel(true);
    }

    /**
     * Init Table Search
     */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
                    icon='search'
                    size='small'
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button 
                    onClick={() => handleReset(dataIndex, clearFilters)} 
                    size='small'
                    style={{ width: 90 }}
                    disabled={!hasFilters}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <Icon 
                type='search' 
                style={{ color: filtered ? '#1890ff' : undefined }} 
            />
        ),
    });

    /**
     * Init Table columns
     */
    const customerColumns = [
        { 
            title: 'Customer Name', 
            dataIndex: 'CustomerName', 
            key: 'CustomerName',
            render: (text: any, record: Invoice, index: number) => (get(record, 'DisplayName') || ''),
            ...getColumnSearchProps('Customer')
        },
        { 
            title: 'Customer Code', 
            dataIndex: 'CustomerCode', 
            key: 'CustomerCode',
            render: (text: any, record: Invoice, index: number) => (get(record, 'CustomerCode') || ''),
            ...getColumnSearchProps('CustomerCode')
        },
        { 
            title: 'Total Amount', 
            dataIndex: 'TotalAmount', 
            key: 'TotalAmount',
            render: (text: any, record: Invoice, index: number) => (handleFormatCurrency(get(record, 'AgedTrialBalance.TotalOwing')).amountDisplay),
        },
    ];

    /**
     * Handle Table search
     * @param selectedKeys 
     * @param dataIndex 
     */
    const handleSearch = (selectedKeys: string[], dataIndex: string, confirm: any) => {
        confirm();
        dispatch(
            applyCustomerHaveNoPaymentPlanFiltersAction({
                filterIndex: dataIndex,
                filterValue: selectedKeys[0]
            })
        );
    };

    /**
     * Handle reset table searching
     * @param dataIndex 
     * @param clearFilters 
     */
    const handleReset = (dataIndex: string, clearFilters: any) => {
        if (!hasFilters) return;

        clearFilters();
        dispatch(
            applyCustomerHaveNoPaymentPlanFiltersAction({
                filterIndex: dataIndex,
                filterValue: undefined
            })
        );
    };

    /**
     * Handle select/unselect table row
     * @param selectedKey
     * @param selected 
     */
    const handleSelectTableRowKey = (selectedKey: InvoiceHaveNoPaymentPlanSelectedModel, selected: boolean) => {
        const selectedSet = new Set((get(tableRowSelection, 'selectedRowKeys') || []).map(
            (selected) => JSON.stringify(selected)
        ));
        const unselectedSet = new Set((get(tableRowSelection, 'unselectedRowKeys') || []).map(
            (unselected) => JSON.stringify(unselected)
        ))
        const stringSelectedKey = JSON.stringify(selectedKey);
        if (selected) {
            selectedSet.add(stringSelectedKey);
            unselectedSet.delete(stringSelectedKey);
        } else {
            unselectedSet.add(stringSelectedKey);
            selectedSet.delete(stringSelectedKey);
        }

        setTableRowSelection({
            ...tableRowSelection,
            selectedRowKeys: Array.from(selectedSet).map(selected => JSON.parse(selected)),
            unselectedRowKeys: Array.from(unselectedSet).map(unselected => JSON.parse(unselected))
        });
    }

    const handleFormSubmit = () => {
        const submitionFormData = {
            ...batchPaymentPlanFormData,
            excludedItems: get(tableRowSelection, 'unselectedRowKeys'),
            requestFromPage: PaymentPlanRequestPageTypes.CUSTOMERS_PAGE
        };
        
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        const refetchInvoicesPayload: any = {
            filters: {
                CustomerCode: customerData[0] ? customerData[0].CustomerCode : undefined,
                CreatedDateMin: "",
                CreatedDateMax: "",
                DueDateMin: "",
                DueDateMax: "",
                AmountType: "",
                AmountValue: "",
                AmountOperator: "",
                InvoiceNumber: "",
                Customer: "",
                CompanyId: selectedCompanyId
            },
            sortBy: customersState.sortBy,
            sortAscending: customersState.sortAscending,
            invoiceState: InvoiceState.OPEN,
            currentPage: 0,
            hasPaymentPlan: false, 
            amountOperator: '>',
            amountType: 'Owing',
            amountValue: 0
        };

        dispatch(
            updateBatchPaymentPlanFormDataRequestAction(
                submitionFormData, 
                refetchInvoicesPayload
            )
        );

        handleNextStepFormClick();
    };

    /**
     * Init Table row selection
     */
    const customerRowSelection = {
        selectedRowKeys: (get(tableRowSelection, 'selectedRowKeys') || []).map((selected) => get(selected, 'CustomerId')),
        onSelect: (
            record: Customer, 
            selected: boolean, 
        ) => {
            handleSelectTableRowKey(
                Object.assign({}, {
                    InvoiceId: '',  //Empty string for Customer
                    CustomerId: get(record, 'Id'),
                    TotalAmount: get(record, 'AgedTrialBalance.TotalOwing')
                }), 
                selected);
        },
        onSelectAll: () => { return; },
        getCheckboxProps: (record: Customer) => ({
            name: record.Id,
        }),
        columnTitle: (<span></span>)
    };

    const handleFormatCurrency = (amount: number) => {
            return {
                amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
                currency: first(map(
                    filter(
                        (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                    ),
                    p => p.value
                ))
            };
        };

    /**
     * Fetch data for the first time
     */
    useEffect(() => {
        fetchInvoicesHaveNoPaymentPlan(0);
    }, []);

    /**
     * Fetch data When filters changing
     */
    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            handleInvoiceData()
        }

        return () => {
            isUnmounted = true
        };
    }, [panelFilters]);

    /**
     * Table scroll listener
     */
    const tableBody = document.querySelector<HTMLTableSectionElement>('.scrolling-table .ant-table-body');
    useEffect(() => {
        if (tableBody) {
            tableBody.addEventListener('scroll', handleTableScroll);
        }

        return () => {
            if (tableBody) {
                tableBody.removeEventListener('scroll', handleTableScroll);
            }
        }
    }, [customerData, tableBody]);
    
    /**
     * Table is scrolling to top when reset data
     */
    useEffect(() => {
        if (batchPaymentPlanCustomerStates.pageData.currentPage === 0) {
            if (tableBody) {
                tableBody.scrollTop = 0;
            }
        }
    }, [batchPaymentPlanCustomerStates.pageData.currentPage, tableBody]);

    /**
     * Auto select new data
     */
    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            const selectedRowKeys = get(tableRowSelection, 'selectedRowKeys');
            let newSelectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[] = [];
            newSelectedRowKeys = uniqWith(
                concat(customerDataToSelectedRowKeys, selectedRowKeys),
                isEqual
            );
            const unselectedRowKeys = get(tableRowSelection, 'unselectedRowKeys');
            if (!isUndefined(unselectedRowKeys) && !isEmpty(unselectedRowKeys)) {
                newSelectedRowKeys = newSelectedRowKeys.filter(
                    (selectedKey: InvoiceHaveNoPaymentPlanSelectedModel) => !unselectedRowKeys.some(
                        (unselectedRowKey) => unselectedRowKey.CustomerId === selectedKey.CustomerId
                    )
                );
            }
            
            setTableRowSelection({
                ...tableRowSelection,
                selectedRowKeys: newSelectedRowKeys
            });
        }
        return () => {
            isUnmounted = true;
        }
    }, [customerData]);

    return (
        <Spin 
            spinning={ batchPaymentPlanCustomerStates.loading } 
            tip={ getTranslatedText('Fetching customers...') }
        >
            <Row>
                <Col span={24}>
                    <Table
                        className='scrolling-table'
                        rowSelection={customerRowSelection as any} 
                        columns={customerColumns}
                        dataSource={customerData}
                        rowKey='Id'
                        pagination={false}
                        locale={{ emptyText: getTranslatedText('No Data') }}
                        scroll={{
                            y: scroll ? usedHeight : currentHeight,
                            scrollToFirstRowOnChange: true
                        }}
                    />
                </Col>
            </Row>
            <Row 
                justify='end'
                gutter={[16, 16]}
            >
                <Col 
                    span={24} 
                    className='ta-right'
                >
                    <Button 
                        onClick={handleFormSubmit}
                        type='primary'
                        disabled={get(tableRowSelection, 'selectedRowKeys').length === 0 && (isEmpty(customerData) || isUndefined(customerData))}
                        style={{ marginRight: '8px'}}
                    >
                        { getTranslatedText('Next') }
                    </Button>
                    <Button 
                        onClick={handleClosePanel} 
                        type='default'
                    >
                        { getTranslatedText('Cancel') }
                    </Button>
                </Col>
                
            </Row>
        </Spin>
    );
};

export default InvoiceNoPaymentPlanListFromCustomerPage;