import update from 'immutability-helper';
import { clone, concat, get, isEqual, keyBy, merge, uniqWith, values } from 'lodash';
import { Reducer } from 'redux';
import { CUSTOMERS_PAGE, DETAILS_TAB, INVOICES_PAGE } from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    invoiceChangesTableFilterOptions,
    invoicesSortByOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { InvoicesActionTypes, InvoicesState } from './types';

// Type-safe initialState!
export const initialState: InvoicesState = {
    loading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: INVOICES_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {
        InvoiceNumber: '',
        Customer: '',
        CustomerCode: '',
        CreatedDateMin: '',
        CreatedDateMax: '',
        DueDateMin: '',
        DueDateMax: '',
        AmountType: '',
        AmountValue: '',
        AmountOperator: '',
    },
    tableFilter: undefined,
    invoiceState: get(invoicesStateFilterOptions, `${0}.value`),
    sortBy: invoicesSortByOptions[0].value, // 2 for Invoice number
    sortAscending: true,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        invoiceChanges: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.INVOICE_CHANGES_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            invoiceChangesTableFilter: get(
                invoiceChangesTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
    },
    compactView: false,
    batchPaymentPlan: {
        invoices: {
            panelFilters: {
                InvoiceCode: undefined,
                CustomerCode: undefined,
                Customer: undefined
            },
            loading: false,
            data: [],
            pageData: {
                pageSize: INVOICES_PAGE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
        },
        customers: {
            panelFilters: {
                InvoiceCode: undefined,
                CustomerCode: undefined,
                Customer: undefined
            },
            loading: false,
            data: [],
            pageData: {
                pageSize: CUSTOMERS_PAGE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
        },
        paymentPlanConfigs: {
            loading: false,
            data: [],
            selectedTemplateId: undefined
        },
        formData: {
            customerIds: [],
            excludedItems: [],
            invoiceItems: [],
            paymentPlanConfig: {
                ConfigId: undefined,
                InstalmentType: undefined,
                Instalments: undefined,
                ScheduleDates: [],
                PayPercent: [],
                SplitInstalmentDateRange: [],
                Name: undefined,
                CutoffDate: undefined,
                StartDelay: undefined,
                FrequencyGap: undefined,
                SpecificPaymentDate: undefined,
                StartTiming: undefined
            },
            requestFromPage: undefined,
            skipCustomerNotification: false,
            isIndividualInvoice: false,
        },
        reviewingBatchPaymentPlan: {},
        reviewingInvoice: {
            loading: false,
            data: [],
        },
        customerManagementFilter: undefined,
        invoiceManagementFilter: undefined
    }
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<InvoicesState> = (state = initialState, action) => {
    switch (action.type) {
        case  InvoicesActionTypes.UPDATE_BATCH_PAYMENT_PLAN_INVOICE_FILTERS: {
            const newBatchPaymentPlan = update(
                state.batchPaymentPlan,
                {
                    invoiceManagementFilter: { $set: action.payload }
                }
            );
            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlan
            }
        }

        case  InvoicesActionTypes.UPDATE_BATCH_PAYMENT_PLAN_CUSTOMER_FILTERS: {
            const newBatchPaymentPlan = update(
                state.batchPaymentPlan,
                {
                    customerManagementFilter: { $set: action.payload }
                }
            );
            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlan
            }
        }

        case InvoicesActionTypes.RESET_BATCH_PAYMENT_PLAN_STATE: {
            return {
                ...state,
                batchPaymentPlan: initialState.batchPaymentPlan
            }
        }
        
        case InvoicesActionTypes.UPDATE_BATCH_PAYMENT_PLAN_FORM_DATA: {
            const newFormData = update(
                state.batchPaymentPlan,
                {
                    formData: { $set: action.payload }
                }
            );

            return {
                ...state,
                batchPaymentPlan: newFormData
            }
        }

        case InvoicesActionTypes.SELECT_PAYMENT_PLAN_TEMPLATE: {
            const newPaymentPlanConfig = update(
                state.batchPaymentPlan.paymentPlanConfigs,
                {
                    selectedTemplateId: { $set: action.payload }
                },
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        paymentPlanConfigs: { $set: newPaymentPlanConfig }
                    }
                )
            }
        }

        case InvoicesActionTypes.APPLY_REVIEWING_BATCH_PAYMENT_PLAN: {
            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        reviewingBatchPaymentPlan: { $set: action.payload }
                    }
                )
            }    
        }

        case InvoicesActionTypes.RESET_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS: {
            const newBatchPaymentPlan = update(
                state.batchPaymentPlan,
                {
                    invoices: { $set: initialState.batchPaymentPlan.invoices }
                }
            );

            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlan
            }
        }

        case InvoicesActionTypes.APPLY_INVOICE_HAVE_NO_PAYMENT_PLAN_FILTERS: {
            const newFilters: any = clone(state.batchPaymentPlan.invoices.panelFilters);
            const { filterIndex, filterValue } = action.payload;
            if (newFilters.hasOwnProperty(filterIndex)) {
                newFilters[filterIndex] = filterValue;
            }
            const newPageData = update(
                state.batchPaymentPlan.invoices.pageData,
                {
                    currentPage: { $set: -1 },
                    hasNextPage: { $set: true }
                }
            )
            const newBatchPaymentPlanInvoices = update(
                state.batchPaymentPlan.invoices,
                {
                    panelFilters: { $set: newFilters },
                    pageData: { $set: newPageData }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        invoices: { $set: newBatchPaymentPlanInvoices } 
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_INVOICES_HAVE_NO_PAYMENT_PLAN_REQUEST: {
            const newBatchPaymentPlanInvoices = update(
                state.batchPaymentPlan.invoices,
                {
                    loading: { $set: true }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        invoices: { $set: newBatchPaymentPlanInvoices }
                    }
                )
            }
        }
        
        case InvoicesActionTypes.GET_INVOICES_HAVE_NO_PAYMENT_PLAN_SUCCESS: {
            const newBatchPaymentPlanInvoices = update(
                state.batchPaymentPlan.invoices,
                {
                    loading: { $set: false },
                    data: {
                        $set: action.payload.pageData.currentPage === 0
                            ? action.payload.data
                            : uniqWith(
                                concat(
                                    state.batchPaymentPlan.invoices.data,
                                    action.payload.data
                                ),
                                isEqual
                            )
                    },
                    pageData: { $set: action.payload.pageData }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        invoices: { $set: newBatchPaymentPlanInvoices }
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_INVOICES_HAVE_NO_PAYMENT_PLAN_FAILED: {
            const newBatchPaymentPlanInvoices = update(
                state.batchPaymentPlan.invoices,
                {
                    loading: { $set: false },
                    data: { $set: initialState.batchPaymentPlan.invoices.data }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan, 
                    { 
                        invoices: { $set: newBatchPaymentPlanInvoices } 
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_CUSTOMER_INVOICES_FOR_COMPANY_REQUEST: {
            const newBatchPaymentPlanReviewingInvoice = update(
                state.batchPaymentPlan.reviewingInvoice,
                {
                    loading: { $set: true }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        reviewingInvoice: { $set: newBatchPaymentPlanReviewingInvoice }
                    }
                )
            };
        }

        case InvoicesActionTypes.UPDATE_BATCH_PAYMENT_PLAN_REVIEWING_INVOICES_DATA: {
            const newBatchPaymentPlanReviewingInvoice = update(
                state.batchPaymentPlan.reviewingInvoice,
                {
                    loading: { $set: false },
                    data: { $set: action.payload }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        reviewingInvoice: { $set: newBatchPaymentPlanReviewingInvoice }
                    }
                )
            };
        }

        case InvoicesActionTypes.RESET_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS: {
            const newBatchPaymentPlan = update(
                state.batchPaymentPlan,
                {
                    customers: { $set: initialState.batchPaymentPlan.customers }
                }
            );

            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlan
            }
        }

        case InvoicesActionTypes.APPLY_CUSTOMER_HAVE_NO_PAYMENT_PLAN_FILTERS: {
            const newFilters: any = clone(state.batchPaymentPlan.customers.panelFilters);
            const { filterIndex, filterValue } = action.payload;
            if (newFilters.hasOwnProperty(filterIndex)) {
                newFilters[filterIndex] = filterValue;
            }
            const newPageData = update(
                state.batchPaymentPlan.customers.pageData,
                {
                    currentPage: { $set: -1 },
                    hasNextPage: { $set: true }
                }
            )
            const newBatchPaymentPlanCustomers = update(
                state.batchPaymentPlan.customers,
                {
                    panelFilters: { $set: newFilters },
                    pageData: { $set: newPageData }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        customers: { $set: newBatchPaymentPlanCustomers } 
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_REQUEST: {
            const newBatchPaymentPlanCustomers = update(
                state.batchPaymentPlan.customers,
                {
                    loading: { $set: true }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        customers: { $set: newBatchPaymentPlanCustomers }
                    }
                )
            }
        }
        
        case InvoicesActionTypes.GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_SUCCESS: {
            const newBatchPaymentPlanCustomers = update(
                state.batchPaymentPlan.customers,
                {
                    loading: { $set: false },
                    data: {
                        $set: action.payload.pageData.currentPage === 0
                            ? action.payload.data
                            : uniqWith(
                                concat(
                                    state.batchPaymentPlan.customers.data,
                                    action.payload.data
                                ),
                                isEqual
                            )
                    },
                    pageData: { $set: action.payload.pageData }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan,
                    {
                        customers: { $set: newBatchPaymentPlanCustomers }
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_CUSTOMERS_HAVE_NO_PAYMENT_PLAN_FAILED: {
            const newBatchPaymentPlanCustomers = update(
                state.batchPaymentPlan.customers,
                {
                    loading: { $set: false },
                    data: { $set: initialState.batchPaymentPlan.customers.data }
                }
            );

            return {
                ...state,
                batchPaymentPlan: update(
                    state.batchPaymentPlan, 
                    { 
                        customers: { $set: newBatchPaymentPlanCustomers } 
                    }
                )
            }
        }

        case InvoicesActionTypes.GET_PAYMENT_PLAN_CONFIG_TEMPLATES_REQUEST: {
            const newBatchPaymentPlanData = update(
                state.batchPaymentPlan,
                {
                    paymentPlanConfigs: {
                        loading: { $set: true }
                    }
                }
            ); 

            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlanData
            }
        }

        case InvoicesActionTypes.GET_PAYMENT_PLAN_CONFIG_TEMPLATES_SUCCESS: {
            const newBatchPaymentPlanData = update(
                state.batchPaymentPlan,
                {
                    paymentPlanConfigs: { 
                        loading: { $set: false },
                        data: { $set: action.payload.data }
                    }
                }
            );

            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlanData
            }
        }

        case InvoicesActionTypes.GET_PAYMENT_PLAN_CONFIG_TEMPLATES_FAILED: {
            const newBatchPaymentPlanData = update(
                state.batchPaymentPlan,
                {
                    paymentPlanConfigs: { $set: initialState.batchPaymentPlan.paymentPlanConfigs }
                }
            );
            return {
                ...state,
                batchPaymentPlan: newBatchPaymentPlanData
            }
        }

        case InvoicesActionTypes.GET_INVOICES_FOR_ORGANISATION_REQUEST:
        case InvoicesActionTypes.GET_INVOICES_REQUEST: {
            return { ...state, loading: true };
        }
        case InvoicesActionTypes.GET_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case InvoicesActionTypes.GET_INVOICES_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case InvoicesActionTypes.UPDATE_INVOICES_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case InvoicesActionTypes.UPDATE_INVOICES_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                invoiceState: action.payload.invoiceState,
            };
        }
        case InvoicesActionTypes.UPDATE_INVOICES_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case InvoicesActionTypes.CLEAR_INVOICES_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                invoiceState: initialState.invoiceState,
                tableFilter: initialState.tableFilter,
            };
        }
        case InvoicesActionTypes.CLEAR_INVOICES_STATE_DATA: {
            return {
                ...initialState,
            };
        }
        case InvoicesActionTypes.CLEAR_INVOICES_LIST_DATA: {
            return {
                ...state,
                loading: false,
                data: [],
                pageData: initialState.pageData,
                errorMessages: initialState.errorMessages,
            };
        }

        //Single Record
        case InvoicesActionTypes.SET_INVOICE_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case InvoicesActionTypes.SET_INVOICE_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }
        case InvoicesActionTypes.GET_ORGANISATION_INVOICE_DATA_REQUEST:
        case InvoicesActionTypes.GET_INVOICE_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case InvoicesActionTypes.GET_INVOICE_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case InvoicesActionTypes.GET_INVOICE_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case InvoicesActionTypes.CLEAR_INVOICE_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For conversation list reducers
        case InvoicesActionTypes.GET_ORGANISATION_INVOICE_CONVERSATION_REQUEST:
        case InvoicesActionTypes.GET_INVOICE_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.GET_INVOICE_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.GET_INVOICE_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For invoice changes list reducers
        case InvoicesActionTypes.GET_ORGANISATION_INVOICE_CHANGES_REQUEST:
        case InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_REQUEST: {
            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.invoiceChanges.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.invoiceChanges.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.invoiceChanges
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.GET_INVOICE_INVOICE_CHANGES_ERROR: {
            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.invoiceChanges.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_FILTERS: {
            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_TABLE_FILTER: {
            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        invoiceChangesTableFilter: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.UPDATE_INVOICE_INVOICE_CHANGES_SORT_BY: {
            const newInvoiceChangesState = update(
                state.activeData.invoiceChanges,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                invoiceChanges: { $set: newInvoiceChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case InvoicesActionTypes.INVOICE_SET_TABLE_COMPACT_VIEW: {
            return {
                ...state,
                compactView: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as invoicesReducer };
